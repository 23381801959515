<template
  ><v-form>
    <v-layout>
      <v-app-bar app height="124" class="align-start elevation-1">
        <v-col cols="12" class="py-0">
          <v-row dense class="align-center flex-nowrap">
            <v-col cols="auto" class="d-flex d-lg-none pa-0">
              <!--Platzhalter für mobile Menu-->
              <div style="width: 50px"></div>
            </v-col>
            <v-col cols="auto">
              <v-toolbar-title>Hersteller erstellen</v-toolbar-title>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn outlined color="red lighten-1" @click="$router.go(-1)">
                <v-icon>mdi-cancel</v-icon>
                <span class="ml-1 d-none d-lg-flex">Abbrechen</span>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn color="green lighten-1 grey--text text--lighten-4" @click="herstellerSpeichern()" :disabled="validSafe">
                <v-icon>mdi-check</v-icon>
                <span class="ml-1 d-none d-lg-flex">Speichern</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-app-bar>

      <v-container fluid class="px-0 pt-0">
        <v-col cols="11" xl="8" lg="9" md="11" class="mx-auto px-0 pt-0">
          <v-row dense class="align-center justify-center pb-5">
            <v-card tile outlined class="flex-grow-1">
              <v-col cols="12">
                <v-card-title class="px-2">
                  <v-row dense>
                    <v-col cols="12">
                      <v-toolbar-title>
                        Hauptdaten
                      </v-toolbar-title>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider inset></v-divider>
                <v-card-text class="px-2 subtitle-2">
                  <v-row dense align="center">
                    <v-col cols="12" xl="4" lg="4" md="5" sm="6">
                      <v-checkbox v-model="newDataset.ist_aktiv" :true-value="1" :false-value="0"
                        ><template v-slot:label>
                          <span class="text--primary subtitle-2">
                            Aktiver Hersteller?
                          </span>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row dense align="start">
                    <v-col cols="12" xl="6" lg="8" md="10" sm="12">
                      <v-row dense>
                        <v-col cols="12" xl="10" lg="10" md="8" sm="8">
                          <span class="font-weight-bold">Name</span>

                          <v-text-field v-model="newDataset.Name" hide-details dense outlined required placeholder="Name"> </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                          <span class="font-weight-bold">Abkürzung</span>

                          <v-text-field v-model="newDataset.Abkürzung" hide-details dense outlined required placeholder="Abkürzung"> </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" xl="10" lg="10" md="8" sm="8">
                          <span class="font-weight-bold">Kundennummer</span>

                          <v-text-field v-model="newDataset.Kundennummer" hide-details dense outlined required placeholder="Kundennummer">
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" xl="6" lg="8" md="10" sm="12">
                      <v-row>
                        <v-col cols="12">
                          <span class="font-weight-bold">Sortiment</span>
                          <v-textarea hide-details dense outlined clearable v-model="newDataset.Sortiment" placeholder="Sortiment"> </v-textarea>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-card>
          </v-row>
          <v-row dense class="align-center justify-center pb-5" v-show="aktiverHersteller">
            <v-card tile outlined class="flex-grow-1">
              <v-col cols="12">
                <v-card-title class="px-2">
                  <v-row dense>
                    <v-col cols="12">
                      <v-toolbar-title>
                        Kontakt
                      </v-toolbar-title>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider inset></v-divider>
                <v-card-text class="px-2 subtitle-2">
                  <v-row dense align="center">
                    <v-col cols="12" xl="5" lg="6" md="5" sm="6">
                      <span class="font-weight-bold">Kontaktperson</span>
                      <v-text-field v-model="newDataset.Kontaktperson" hide-details dense outlined required placeholder="Kontaktperson">
                      </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row dense align="center">
                    <v-col cols="12" xl="5" lg="5" md="5" sm="6">
                      <span class="font-weight-bold">Telefon</span>
                      <v-text-field v-model="newDataset.Telefon" hide-details dense outlined required placeholder="Telefon"> </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" xl="7" lg="7" md="7" sm="8">
                      <span class="font-weight-bold">Email</span>
                      <v-text-field v-model="newDataset.Email" hide-details dense outlined required placeholder="Email"> </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row dense align="center">
                    <v-col cols="12" xl="8" lg="8" md="8" sm="10">
                      <span class="font-weight-bold">IBAN</span>
                      <v-text-field v-model="newDataset.IBAN" hide-details dense outlined required placeholder="IBAN"> </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                      <span class="font-weight-bold">BIC</span>
                      <v-text-field v-model="newDataset.BIC" hide-details dense outlined required placeholder="BIC"> </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row dense align="center">
                    <v-col cols="12" xl="6" lg="8" md="6" sm="8">
                      <span class="font-weight-bold">Website</span>
                      <v-text-field v-model="newDataset.Website" hide-details dense outlined required placeholder="www.website.de"> </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" xl="6" lg="8" md="6" sm="8">
                      <span class="font-weight-bold">Shop</span>
                      <v-text-field v-model="newDataset.Shop" hide-details dense outlined required placeholder="www.shop.de"> </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-card>
          </v-row>
          <v-row dense class="align-center justify-center pb-5" v-show="aktiverHersteller">
            <v-card tile outlined class="flex-grow-1">
              <v-col cols="12">
                <v-card-title class="px-2">
                  <v-row dense>
                    <v-col cols="12">
                      <v-toolbar-title>
                        Anschrift
                      </v-toolbar-title>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider inset></v-divider>
                <v-card-text class="px-2 subtitle-2">
                  <v-row dense align="center">
                    <v-col cols="12" xl="4" lg="5" md="6" sm="8">
                      <span class="font-weight-bold">Land</span>
                      <v-text-field v-model="newDataset.Land" hide-details dense outlined required placeholder="Land"> </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row dense align="center">
                    <v-col cols="9" xl="6" lg="6" md="8" sm="9">
                      <span class="font-weight-bold">Straße</span>
                      <v-text-field v-model="newDataset.Straße" hide-details dense outlined required placeholder="Straße"> </v-text-field>
                    </v-col>
                    <v-col cols="3" xl="2" lg="2" md="2" sm="3">
                      <span class="font-weight-bold">Nr.</span>
                      <v-text-field v-model="newDataset.Hausnummer" hide-details dense outlined required placeholder="Nr."> </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row dense align="center">
                    <v-col cols="4" xl="3" lg="3" md="3" sm="4">
                      <span class="font-weight-bold">PLZ</span>
                      <v-text-field v-model="newDataset.PLZ" hide-details dense outlined required placeholder="PLZ"> </v-text-field>
                    </v-col>
                    <v-col cols="8" xl="5" lg="6" md="8" sm="8">
                      <span class="font-weight-bold">Ort</span>
                      <v-text-field v-model="newDataset.Ort" hide-details dense outlined required placeholder="Ort"> </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-card>
          </v-row>
        </v-col>
      </v-container>
    </v-layout>

    <LeavePathWrapper ref="leavePath">
      <LeavePathDialog />
    </LeavePathWrapper>
  </v-form>
</template>

<script>
import LeavePathWrapper from "../../../../components/dialogs/LeavePathDialog.vue";
import LeavePathDialog from "../../../../components/dialogs/LeavePathDialog.vue";

export default {
  components: {
    LeavePathWrapper,
    LeavePathDialog,
  },

  created() {
    this.initialize();
  },

  mounted() {
    this.initialize();
  },

  beforeRouteLeave(to, from, next) {
    if (this.saved === true || !this.checkDifferences) {
      next();
    } else if (this.saved === false) {
      this.$refs.leavePath.open().then((result) => {
        if (result === true) {
          next();
        } else if (result === false) {
          next(false);
        }
      });
    }
  },

  data: () => ({
    aktiverHersteller: true,
    defaultDataset: {
      Abkürzung: null,
      Name: null,
      Kundennummer: null,
      Sortiment: null,
      Land: null,
      Straße: null,
      Hausnummer: null,
      PLZ: null,
      Ort: null,
      Kontaktperson: null,
      Telefon: null,
      Email: null,
      IBAN: null,
      BIC: null,
      Website: null,
      Shop: null,
      ist_aktiv: null,
    },
    newDataset: {},
    initialDataset: {},
    saved: false,
  }),

  computed: {
    checkDifferences() {
      if (
        this.newDataset.Hersteller_ID === this.initialDataset.Hersteller_ID &&
        this.newDataset.Name === this.initialDataset.Name &&
        this.newDataset.Abkürzung === this.initialDataset.Abkürzung &&
        this.newDataset.Kundennummer === this.initialDataset.Kundennummer &&
        this.newDataset.Kontaktperson === this.initialDataset.Kontaktperson &&
        this.newDataset.Telefon === this.initialDataset.Telefon &&
        this.newDataset.Email === this.initialDataset.Email &&
        this.newDataset.Website === this.initialDataset.Website &&
        this.newDataset.Shop === this.initialDataset.Shop &&
        this.newDataset.IBAN === this.initialDataset.IBAN &&
        this.newDataset.BIC === this.initialDataset.BIC &&
        this.newDataset.Straße === this.initialDataset.Straße &&
        this.newDataset.Hausnummer === this.initialDataset.Hausnummer &&
        this.newDataset.PLZ === this.initialDataset.PLZ &&
        this.newDataset.Ort === this.initialDataset.Ort &&
        this.newDataset.Sortiment === this.initialDataset.Sortiment &&
        this.newDataset.ist_aktiv === this.initialDataset.ist_aktiv
      ) {
        return false;
      } else return true;
    },

    validSafe() {
      if (!this.newDataset.Name || !this.newDataset.Abkürzung || !this.newDataset.Sortiment) {
        return true;
      } else return false;
    },
  },

  methods: {
    async initialize() {
      this.newDataset = Object.assign({}, this.defaultDataset);
      this.initialDataset = Object.assign({}, this.defaultDataset);
    },

    async herstellerSpeichern() {
      const datasetToInsert = {};

      if (Object.keys(datasetToInsert).length === 0) {
        Object.assign(datasetToInsert, {
          Abkürzung: this.newDataset.Abkürzung,
          Name: this.newDataset.Name,
          Kundennummer: this.newDataset.Kundennummer,
          Sortiment: this.newDataset.Sortiment,
          Land: this.newDataset.Land,
          Straße: this.newDataset.Straße,
          Hausnummer: this.newDataset.Hausnummer,
          PLZ: this.newDataset.PLZ,
          Ort: this.newDataset.Ort,
          Kontaktperson: this.newDataset.Kontaktperson,
          Telefon: this.newDataset.Telefon,
          Email: this.newDataset.Email,
          IBAN: this.newDataset.IBAN,
          BIC: this.newDataset.BIC,
          Website: this.newDataset.Website,
          Shop: this.newDataset.Shop,
          ist_aktiv: this.newDataset.ist_aktiv,
        });
      }

      const saveresponse = await fetch("/api/database/manufacturers/dataset/insert", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetToInsert),
      });
      const jsonhersteller = await saveresponse.json();
      const newdatasetid = jsonhersteller.lastInsertRowid;

      this.$root.actionSnackbar.show({
        dataset: "Hersteller",
        action: "erstellt",
      });
      this.saved = true;
      this.$router.replace({
        path: `/wws/database/manufacturers/dataset/show/${newdatasetid}`,
      });
    },
  },
};
</script>
